let checkClickOutside = (event, nodeName, className) => {
  // event - событие
  // nodeName - наименования блока DOM
  // className - уникальное имя класса, на котором происходит отслеживание
  // функция возвращает true если клик был вне компонента

  let path = event.path || (event.composedPath && event.composedPath());
  let checkEndFunction = false;

  for (let i = 0; i < path.length; i++) {
    if ((String(path[i].nodeName) === nodeName) && path[i].className && (path[i].className.indexOf(className) !== -1)) {
      checkEndFunction = true;
      break;
    }
  }

  if (!checkEndFunction) return true   // если был клик не на компоненте
  return false
};

let visibleElement = (target) => {
  // Все позиции элемента
  let targetPosition = {
    top: (window.pageYOffset || document.documentElement.scrollTop) + target.getBoundingClientRect().top,
    bottom: (window.pageYOffset || document.documentElement.scrollTop) + target.getBoundingClientRect().bottom
  },
  windowPosition = {
    top: (window.pageYOffset || document.documentElement.scrollTop),
    bottom: (window.pageYOffset || document.documentElement.scrollTop) + document.documentElement.clientHeight
  };

  console.log('targetPosition', targetPosition);
  console.log('windowPosition', windowPosition);

  if (targetPosition.bottom > windowPosition.top && targetPosition.top < windowPosition.bottom) {
    return true;
  } else {
    return false;
  }
};

export {checkClickOutside, visibleElement}