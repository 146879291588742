<template>
  <div class="header_component"
       id="header"
  >
    <transition name="fade">
      <div class="background background__theme_main"
           v-if="visibleMenu"
           @click="visibleMenu = false"
      ></div>
    </transition>

    <div class="header_content block__theme_desktop">
      <router-link :to="{name: 'main', params: {}}">
        <div class="logo"
             v-html="logoSVG"
        ></div>
      </router-link>

      <div class="menu">
        <div class="item item__type_parent header_component__menu_item"
             :class="{
                  'item__active': item.nameActivePages.includes($route.name),
                  'item__child_open': indexActiveMenuItem === i,
               }"
             v-for="(item, i) in menu"
             :key="'menu_item_' + i"
             @click="goToPage(item, item.children.length === 0)"
        >
          <div class="item_content"
               @click="visibleMenuChildItems(i)"
          >
            <div class="title title__medium">
              {{item.title}}
            </div>

            <div class="down_svg"
                 v-if="item.children.length > 0"
            >
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.875 0.875L0.125 0.875L4.5 5.25L8.875 0.875Z" fill="#AEBCD6"/>
              </svg>
            </div>
          </div>

          <transition name="fade">
            <div class="children menu_children"
                 v-if="(item.children.length > 0) && (indexActiveMenuItem === i)"
            >
              <div class="children_item"
                   :class="{
                      'children_item__active': item.nameActivePages.includes($route.name)
                   }"
                   v-for="(elem, j) in item.children"
                   :key="'menu_item_' + i + '_children_' + j"
                   @click="goToPage(elem)"
              >
                <div class="title title__small">{{elem.title}}</div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="container container__type_info">
        <div class="block_tel">
          <div class="tel"
               @click="call()"
          >{{contactTel}}</div>
          <div class="text text__call"
               @click="openFormFeedback()"
          >
            Перезвонить вам?
          </div>
        </div>

        <div class="buttons">
          <div class="button button__theme_free button__theme_white"
               @click="scrollTo('free_lesson')"
          >
            Бесплатная диагностика
          </div>

          <div class="button button__theme_auth button__theme_purple"
              @click="authorization()"
          >
            Вход
          </div>
        </div>
      </div>
    </div>

    <div class="header_content block__theme_tablet">
      <div class="logo"
           v-html="logoSVG"
      ></div>

      <div class="button button__theme_menu"
           @click="changeVisibilityMenu()"
      >
        <div class="svg_container">
          <transition name="fadeFast" mode="out-in">
            <div class="svg svg__theme_menu"
                 v-if="!visibleMenu"
                 v-html="menuStylisticSvg.menu"
            ></div>
            <div class="svg svg__theme_close"
                 v-else
                 v-html="menuStylisticSvg.close"
            ></div>
          </transition>
        </div>

        <div class="text">Меню</div>
      </div>

      <transition name="fade">
        <div class="menu"
             v-if="visibleMenu"
        >
          <div class="item item__type_parent header_component__menu_item"
               :class="{
                'item__active': item.nameActivePages.includes($route.name),
                'item__child_open': indexActiveMenuItem === i,
             }"
               v-for="(item, i) in menu"
               :key="'menu_item_' + i"
               @click="goToPage(item, item.children.length === 0)"
          >
            <div class="block block__column item_main">
              <div class="item_content"
                   @click="visibleMenuChildItems(i)"
              >
                <div class="block block__row">
                  <div class="svg svg__theme_logo"
                       v-html="item.svg"
                  ></div>

                  <div class="title title__medium">
                    {{item.title}}
                  </div>
                </div>

                <div class="block block__row">
                  <div class="svg svg__theme_right"
                       v-if="item.children.length === 0"
                  >
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.2917 7.04167L10.2917 17.875L15.7084 12.4583L10.2917 7.04167Z" fill="#C0CADC"/>
                    </svg>
                  </div>

                  <div class="svg svg__theme_down"
                       :class="{'svg__theme_active': item.showChildren}"
                       v-else
                  >
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.58331 15.1667L18.4166 15.1667L13 9.75001L7.58331 15.1667Z" fill="#C0CADC"/>
                    </svg>
                  </div>
                </div>
              </div>

              <SlideUpDown
                v-if="item.children.length > 0"
                v-model="item.showChildren"
                :duration="durationSlideUpDown"
                responsive
              >
                <div class="children menu_children">
                  <div class="block_line"></div>

                  <div class="children_container">
                    <div class="children_item"
                         :class="{
                          'children_item__active': item.nameActivePages.includes($route.name)
                       }"
                         v-for="(elem, j) in item.children"
                         :key="'menu_item_' + i + '_children_' + j"
                         @click="goToPage(elem)"
                    >
                      <div class="title title__small">{{elem.title}}</div>
                    </div>
                  </div>
                </div>
              </SlideUpDown>
            </div>

            <div class="item_border"></div>
          </div>

          <div class="item item__type_parent header_component__menu_item">
            <div class="block block__column item_main">
              <div class="item_content">
                <div class="block block__row">
                  <div class="svg svg__theme_logo"
                       v-html="menuStylisticSvg.phone"
                  ></div>

                  <div class="title title__medium">
                    {{ contactTel }}
                  </div>
                </div>

                <div class="block block__row">
                  <div class="button button__theme_call"
                       @click="call()"
                  >
                    Позвонить
                  </div>
                </div>
              </div>
            </div>

            <div class="item_border"></div>
          </div>

          <div class="buttons_management">
            <div class="button button__theme_registration"
                 @click="scrollTo('free_lesson')"
            >
              Бесплатная диагностика
            </div>

            <div class="button button__theme_authorization"
                 @click="authorization()"
            >
              Вход в систему
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SlideUpDown from 'vue3-slide-up-down';
import {logoPlatform, menuStylisticSvg} from '../../../svgStore/svgStoreFiles';
import Methods from "./Composables/Methods";
import ManagementMenu from "./Composables/ManagementMenu";

export default {
  name: "HeaderComponent",

  setup() {
    const durationSlideUpDown = 500;
    let logoSVG = logoPlatform; // логотип сайта

    let {
      indexActiveMenuItem,
      menu,
      contactTel,
      visibleMenu,
      changeVisibilityMenu,
      visibleMenuChildItems,
    } = ManagementMenu();

    let {
      scrollTo,
      authorization,
      registration,
      call,
      goToPage,
      openFormFeedback,
    } = Methods(contactTel, visibleMenu);


    return {
      menuStylisticSvg,
      durationSlideUpDown,
      logoSVG,
      menu,
      indexActiveMenuItem,
      contactTel,
      visibleMenu,
      changeVisibilityMenu,
      visibleMenuChildItems,
      scrollTo,
      authorization,
      registration,
      call,
      goToPage,
      openFormFeedback,
    }
  },

  components: {
    SlideUpDown,
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.header_component .block__theme_desktop {
  display: flex;
}

.header_component .block__theme_tablet {
  display: none;
}

.header_component .background__theme_main {
  display: none;
}

.block {
  display: flex;
}

.block__column {
  display: flex;
  flex-direction: column;
}

.block__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 20;
  background-color: $color_white;
  width: 100%;
  height: 88px;
  border-bottom: 1px solid $color_cyanite_5;
}

.header_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1294px;
  height: 100%;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menu {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 26px;
  align-items: center;
}

.item__active {
  .item_content:hover {
    cursor: default;
  }

  .title {
    color: $color_purple_3;
  }

  .down_svg svg path {
    fill: $color_purple_3;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    color: $color_cyanite_2;
    transition: $transition_medium;
  }

  .title__medium {
    font-size: 16px;
    line-height: 20px;
  }

  .down_svg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: $transition_medium;
    margin-left: 7px;
    margin-top: 2px;

    svg {
      transition: $transition_medium;
    }

    svg path {
      transition: $transition_medium;
    }
  }

  .item_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      .title {
        color: $color_purple_3;
      }

      .down_svg svg path {
        fill: $color_purple_3;
      }
    }
  }
}

.item__child_open {
  .down_svg {
    transform: rotate(180deg);
  }
}

.item__active {
  .item_content {
    .title {
      color: $color_purple_3;
    }

    svg path {
      fill: $color_purple_3;
    }
  }
}

.menu_children {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 22px;
  background-color: $color_white;
  border: 1px solid $color_grey_5;
  border-radius: 8px;
  padding: 12px 24px;

  .children_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    cursor: pointer;

    &:hover .title__small {
      color: $color_purple_3;
    }
  }

  .children_item__active {
    cursor: default;
    font-weight: 500;
  }

  .title__small {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $color_black;
    transition: $transition_medium;
  }
}

.container__type_info {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  grid-gap: 16px;
}

.block_tel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .tel {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    transition: $transition_medium;

    &:hover {
      color: $color_purple_3;
    }
  }

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: $color_purple_3;
    transition: $transition_medium;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 14px;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $transition_medium;
}

.button__theme_free {
  width: 224px;
  height: 48px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid $color_purple_3;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
}

.button__theme_auth {
  width: 120px; // width: 140px;
  height: 48px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid $color_purple_3;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
}

.button__theme_purple {
  color: $color_white;
  background-color: $color_purple_3;

  &:hover {
    background-color: $color_purple_1;
  }
}

.button__theme_white {
  color: $color_purple_3;
  background-color: rgba(255, 255, 255, 0.0);

  &:hover {
    color: $color_white;
    background-color: $color_purple_1;
  }
}


@media all and (min-width: 1400px) and (max-width: 1600px) {
  .header_content {
    max-width: 1220px;
  }
}

@media all and (min-width: 1200px) and (max-width: 1399px) {
  .header_component {
    height: 88px;
  }

  .header_content {
    margin: 0 60px;
    max-width: 100%;
  }

  .item .title__medium {
    font-size: 15px;
    line-height: 21px;
  }

  .menu {
    grid-gap: 30px;
  }

  .menu_children {
    top: 22px;
    border-radius: 6px;
    padding: 8px 16px;
  }

  .menu_children .children_item {
    padding: 6px 0;
  }

  .menu_children .title__small {
    font-size: 15px;
    line-height: 19px;
  }

  .block_tel .tel {
    font-size: 15px;
    line-height: 19px;
  }

  .button__theme_free {
    width: 200px;
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
  }

  .button__theme_auth {
    width: 106px;
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
  }

  .buttons {
    grid-gap: 12px;
  }
}

.svg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// tablets
@media all and (max-width: 1199px) {
  .menu_children .children_item:hover .title__small {
    color: #C0CADC;
  }
}

@media all and (min-width: 600px) and (max-width: 1199px) {
  .header_component .block__theme_desktop {
    display: none;
  }

  .header_component .block__theme_tablet {
    display: flex;
  }

  .button__theme_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 42px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $color_purple_3;

    .svg_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: white;
      margin: 2px 0 0 6px;
    }
  }

  .header_component .background__theme_main {
    display: flex;
  }

  .background__theme_main {
    width: 100vw;
    height: calc(100vh - 72px);
    position: absolute;
    top: 72px;
    left: 0;
    background-color: rgba(51, 51, 51, 0.5);
  }

  .menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 380px;
    height: calc(100vh - 72px);
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 72px;
    grid-gap: 0;
  }

  .header_component__menu_item {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item_border {
      display: flex;
      background-color: $color_cyanite_5;
      width: 100%;
      height: 1px;
    }
  }

  .menu .item__active .item_content {
    .title__medium {
      color: $color_purple_3;
    }
  }

  .menu .item__child_open .item_content {
    .title__medium {
      color: $color_purple_3;
    }

    .svg__theme_down {
      transform: rotate(0deg);
    }

    &:hover {
      .title__medium {
        color: $color_purple_3;
      }
    }
  }

  .item_main {
    width: 100%;
  }

  .item .item_content:hover .title {
    color: $color_cyanite_3;
  }

  .item .item_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 24px;
    width: calc(100% - 48px);

    .title__medium {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $color_cyanite_3;
      margin: 2px 0 0 12px;
      transition: $transition_medium;
    }

    .svg {
      transition: $transition_medium;
    }

    .svg__theme_down {
      transform: rotate(180deg);
    }
  }

  .button__theme_call {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $color_purple_3;
    width: 116px;
    height: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    border-radius: 4px;
    color: white;
  }

  .buttons_management {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);
    margin: auto 0 24px 0;
    padding-top: 24px;

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid $color_purple_3;
      border-radius: 4px;
      height: 46px;
      width: 100%;
      box-sizing: border-box;
      transition: $transition_medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .button__theme_authorization {
      background-color: $color_purple_3;
      color: $color_white;
    }

    .button__theme_registration {
      background-color: rgba(255, 255, 255, 0.0);
      color: $color_purple_3;
    }
  }

  .menu_children {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    position: relative;
    left: auto;
    top: auto;
    background-color: rgba(255, 255, 255, 0.0);
    border: none;
    border-radius: 0;
    padding: 0 24px 0 34px;

    .block_line {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 1px;
      height: calc(100% - 21px);
      background-color: $color_cyanite_5;
      margin-right: 18px;
    }

    .children_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 0;
      cursor: pointer;
      height: 54px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        transition: $transition_medium;
        color: $color_cyanite_3;
      }
    }

    .children_item__active {
      color: $color_purple_3;
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .header_content {
    width: 820px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  .header_component {
    height: 72px;
    border-bottom: 1px solid #E3E8F4;
  }
}

@media all and (min-width: 600px) and (max-width: 899px) {
  .header_content {
    width: calc(100% - 80px);
    max-width: 820px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  .header_component {
    height: 64px;
    border-bottom: 1px solid #E3E8F4;

    .background__theme_main {
      height: calc(100vh - 64px);
      top: 64px;
    }
  }

  .menu {
    height: calc(100vh - 64px);
    top: 64px;
  }
}

// mobile
@media all and (max-width: 599px) {
  .header_component .block__theme_desktop {
    display: none;
  }

  .header_component .block__theme_tablet {
    display: flex;
  }

  .button__theme_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 42px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $color_purple_3;

    .svg_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: white;
      margin: 2px 0 0 6px;
    }
  }

  .header_component .background__theme_main {
    display: flex;
  }

  .background__theme_main {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 64px);
    top: 64px;
    left: 0;
    background-color: rgba(51, 51, 51, 0.5);
  }

  .header_content {
    width: calc(100% - 32px);
    max-width: 820px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  .header_component {
    height: 64px;
    border-bottom: 1px solid #E3E8F4;
  }

  .menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 64px;
    grid-gap: 0;
  }

  .header_component__menu_item {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item_border {
      display: flex;
      background-color: $color_cyanite_5;
      width: 100%;
      height: 1px;
    }
  }

  .menu .item__active .item_content {
    .title__medium {
      color: $color_purple_3;
    }
  }

  .menu .item__child_open .item_content {
    .title__medium {
      color: $color_purple_3;
    }

    .svg__theme_down {
      transform: rotate(0deg);
    }

    &:hover {
      .title__medium {
        color: $color_purple_3;
      }
    }
  }

  .item_main {
    width: 100%;
  }

  .item .item_content:hover .title {
    color: $color_cyanite_3;
  }

  .item .item_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 16px;
    width: calc(100% - 32px);

    .title__medium {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $color_cyanite_3;
      margin: 2px 0 0 12px;
      transition: $transition_medium;
    }

    .svg {
      transition: $transition_medium;
    }

    .svg__theme_down {
      transform: rotate(180deg);
    }
  }

  .button__theme_call {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $color_purple_3;
    width: 116px;
    height: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    border-radius: 4px;
    color: white;
  }

  .buttons_management {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);
    margin: 0 0 24px 0;
    padding-top: 24px;

    .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid $color_purple_3;
      border-radius: 4px;
      height: 46px;
      width: 100%;
      box-sizing: border-box;
      transition: $transition_medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .button__theme_authorization {
      background-color: $color_purple_3;
      color: $color_white;
    }

    .button__theme_registration {
      background-color: rgba(255, 255, 255, 0.0);
      color: $color_purple_3;
    }
  }

  .menu_children {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    position: relative;
    left: auto;
    top: auto;
    background-color: rgba(255, 255, 255, 0.0);
    border: none;
    border-radius: 0;
    padding: 0 24px 0 34px;

    .block_line {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 1px;
      height: calc(100% - 21px);
      background-color: $color_cyanite_5;
      margin-right: 18px;
    }

    .children_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 0;
      cursor: pointer;
      height: 54px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        transition: $transition_medium;
        color: $color_cyanite_3;
      }
    }

    .children_item__active {
      color: $color_purple_3;
    }
  }
}

@media all and (max-width: 339px) {
  .button__theme_call {
    width: 100px;
    height: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
}
</style>