<template>
  <div class="component">
    <h1 class="seo-el seo-el__hidden">{{ getMetaInfo.textH1 }}</h1>

    <div class="wrappen" id="contract">
      <div class="contract">
        <div class="header">
          <div class="logo"
               @click="goMainPage()"
          >
            <svg data-v-4099e9a9="" width="111" height="34" viewBox="0 0 111 34"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path data-v-4099e9a9=""
                    d="M1.57987 33.9429C1.05612 25.5961 0.534264 17.2785 0 8.76257L29.9072 33.9429H1.57987Z"
                    fill="#424071"></path>
              <path data-v-4099e9a9=""
                    d="M48 8.2261C47.3169 15.3143 46.6516 22.2301 45.9835 29.1461L45.856 29.1844C43.193 24.6145 40.5255 20.0373 37.8536 15.4528L48 8.2261Z"
                    fill="#F8C946"></path>
              <path data-v-4099e9a9=""
                    d="M13.4441 15.9403L23.5854 0L45.7634 34H33.3575L13.4441 15.9403Z"
                    fill="#E46E21"></path>
              <path data-v-4099e9a9=""
                    d="M104.488 13.1855C103.742 14.3567 102.997 15.5282 102.251 16.7C99.1402 21.6032 96.033 26.5084 92.9293 31.4157C92.6669 31.8307 92.394 32.0074 91.8949 31.9998C89.9613 31.9703 88.0269 31.9871 86.0283 31.9871V29.0419H88.2834V9.99377H86V7.06888H94.6708V9.95077H92.3864V25.869C92.5905 25.583 92.7241 25.4113 92.8404 25.2281C96.5723 19.3601 100.3 13.4893 104.024 7.61581C104.298 7.18169 104.574 6.98819 105.106 7.00056C106.815 7.04091 108.526 7.01528 110.238 7.01528H111V9.92426H108.718V29.0127H110.417V31.9338H104.674V13.2529L104.488 13.1855Z"
                    fill="#1C1E52"></path>
              <path data-v-4099e9a9=""
                    d="M69.5183 22.1655V29.0526H71.0903V32H63.8202V29.1108L65.3354 29.0343V10.0574H63V7.07895C63.146 7.05591 63.2974 7.01042 63.4494 7.01071C67.0544 7.01455 70.6611 6.96138 74.2631 7.06152C75.7023 7.10715 77.1297 7.33547 78.5105 7.74092C81.118 8.50333 82.6898 10.2869 82.9223 13.0284C83.0309 14.3078 83.033 15.619 82.8819 16.8922C82.5973 19.2905 80.7109 21.1328 77.9276 21.6775C76.3648 21.983 74.7497 22.0541 73.1533 22.1501C71.9753 22.2231 70.7893 22.1655 69.5183 22.1655ZM69.529 19.4916C71.7243 19.3525 73.8813 19.3253 76.0041 19.0438C77.4982 18.8459 78.5504 17.8182 78.7942 16.312C78.9596 15.2888 78.9266 14.1873 78.7383 13.1652C78.4605 11.6587 77.4571 10.5587 75.9256 10.3528C73.8281 10.0704 71.6946 10.05 69.529 9.91442V19.4916Z"
                    fill="#1C1E52"></path>
            </svg>
          </div>
          <div class="backcourse" @click="goMainPage()">
            <div class="svg">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.7477 4.33398L6.40479 10.0007L11.7477 15.6673L13.0715 14.2633L9.05235 10.0007L13.0715 5.73799L11.7477 4.33398Z"
                  fill="#8C71FE"/>
              </svg>
            </div>
            <span class="header_text">На главную</span>
          </div>
        </div>


        <transition name="fadeIn">
          <WaitingLoading
            v-show="!dataReceived"
            :showComponent="!dataReceived"
            :isLoadedForAllPage="true"
          ></WaitingLoading>
        </transition>

        <transition name="fadeOut">
          <div class="block block__column"
               v-if="dataReceived"
          >
            <div class="block block__column license_container"
                 v-if="$route.name === 'license'"
            >
              <div class="license_image">
                <img class="image image__theme_license_1"
                     v-for="(item, i) in licenseImages"
                     :key="'license_' + i"
                     :src="item"
                >
              </div>
            </div>

            <div class="contract_main"
                 :class="{'contract_main__style_column': items.length === 0}"
                 v-else
            >
              <div class="contract_main_info">
                <div class="general_position style_block"
                >
                  <div class="info" id="info"
                       v-html="contract"
                  ></div>
                </div>
              </div>

              <div class="contract_main_panel"
                   v-if="items.length > 0"
              >
                <div class="contract_main_panel_block">
                  <div class="contract_main_panel_check"
                       v-for="(item, i) in items"
                       :key="item.id"
                       @click="gotoElem(item, item.id, i)"
                  >
                    <div class="circle" v-if="(i !== indexActiveItems)">

                    </div>
                    <svg v-else-if="item.text !== ''" width="28" height="28"
                         viewBox="0 0 28 28" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <circle cx="14" cy="14" r="14" fill="#8C71FE"/>
                      <path
                        d="M11.1306 8.68161L11.1299 8.68108C11.1047 8.66358 11.0713 8.66203 11.0459 8.67543L11.0448 8.67602L11.0448 8.67602C11.0171 8.69057 11 8.71888 11 8.75001V19.25C11 19.2809 11.0168 19.309 11.0442 19.3237C11.0575 19.3304 11.0707 19.3333 11.0833 19.3333C11.0993 19.3333 11.1156 19.3288 11.1311 19.3181C11.1312 19.318 11.1312 19.318 11.1313 19.318L18.7139 14.0685C18.7139 14.0685 18.714 14.0684 18.714 14.0684C18.7368 14.0525 18.75 14.027 18.75 14C18.75 13.9731 18.7368 13.9475 18.714 13.9316L11.1306 8.68161ZM11.1306 8.68161L18.7139 13.9316L11.1306 8.68161ZM11.9513 9.45249L11.1667 8.90934V9.8636V18.1364V19.0907L11.9513 18.5475L17.9263 14.4111L18.5202 14L17.9263 13.5889L11.9513 9.45249Z"
                        fill="none" stroke="white"/>
                    </svg>
                    <div class="text">
                      {{ item.text }}
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import {send} from '../../../fetchData/fetchData';
import WaitingLoading from '@/components/DefaultComponents/LoadersComponent/WaitingLoading.vue';
import {useMeta} from "vue-meta";
import {computed, reactive} from "vue";
import {useRoute} from "vue-router";

export default {
 setup() {
   let metaInfo = reactive({
     'personalData': {
       title: 'Политика конфиденциальности учебного центра - Репетиторская империя',
       description: 'Политика конфиденциальности онлайн-школы подготовки к ЕГЭ «Репетиторская империя». Ознакомьтесь с информацией об учебном центре на нашем сайте.',
       textH1: 'Политика конфиденциальности',
     },
     'contract': {
       title: 'Оферта учебного центра - Репетиторская империя',
       description: 'Оферта на заключение договора об оказании платных услуг онлайн-школы подготовки к ЕГЭ «Репетиторская империя». Ознакомьтесь с информацией об учебном центре на нашем сайте.',
       textH1: 'Оферта',
     },
     'license': {
       title: 'Лицензия на образовательную деятельность -  учебный центр Репетиторская империя',
       description: 'Лицензия на образовательную деятельность онлайн-школы подготовки к ЕГЭ «Репетиторская империя». Ознакомьтесь с информацией об учебном центре на нашем сайте.',
       textH1: 'Лицензия на образовательную деятельность',
     },
   });

   let getMetaInfo = computed(() => { // возвращает репетиторов с учетом фильтрации
     const route = useRoute();
     return metaInfo[route.name];
   });

    useMeta({
      title: getMetaInfo.value.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: getMetaInfo.value.description,
        },
        {
          vmid: 'robots',
          name: 'robots',
          content: null,
        }
      ]
    })

   return {
     metaInfo,
     getMetaInfo,
   }
  },

  data() {
    return {
      dataReceived: false, // данные от апи получены?
      scrollActive: false,
      contract: '',
      licenseImages: [
        require('@/assets/img/MainPage/license.png'),
        require('@/assets/img/MainPage/license2.png')
      ],
      indexActiveItems: 0,
      documentScroll: 0,
      items: [
       /* {
          id: 1,
          text: 'Общие положения',
        },
        {
          id: 2,
          text: 'Предмет политики конфиденциальности',
        },
        {
          id: 3,
          text: 'Цели обработки персональной информации пользователей',
        },
        {
          id: 4,
          text: 'Условия обработки персональной информации и её передачи третьим лицам',
        },
        {
          id: 5,
          text: 'Права и обязанности сторон',
        },
        {
          id: 6,
          text: 'Ответственность сторон',
        },
        {
          id: 7,
          text: 'Разрешение споров',
        },
        {
          id: 8,
          text: 'Дополнительные условия',
        },
        {
          id: 9,
          text: 'Реквизиты оператора персональных данных',
        },*/
      ],

    }
  },

  created() {

    if (this.$route.name === 'contract') {
      this.items = [
        {
          id: 1,
          text: 'Предмет и порядок заключения договора',
        },
        {
          id: 2,
          text: 'Обязанности сторон',
        },
        {
          id: 3,
          text: 'Порядок расчётов и приёма услуг, предоставления доступа к Проекту и Информационным материалам',
        },
        {
          id: 4,
          text: 'Ответственность сторон',
        },
        {
          id: 5,
          text: 'Порядок расторжения и изменения договора',
        },
        {
          id: 6,
          text: 'Порядок урегулирования конфликтов',
        },
        {
          id: 7,
          text: 'Прочие положения',
        },
        {
          id: 8,
          text: 'Реквизиты Исполнителя',
        },
      ];
    } else if (this.$route.name === 'personalData') {
      this.items = [
        {
          id: 1,
          text: 'Общие положения',
        },
        {
          id: 2,
          text: 'Предмет политики конфиденциальности',
        },
        {
          id: 3,
          text: 'Цели обработки персональной информации пользователей',
        },
        {
          id: 4,
          text: 'Условия обработки персональной информации и её передачи третьим лицам',
        },
        {
          id: 5,
          text: 'Права и обязанности сторон',
        },
        {
          id: 6,
          text: 'Ответственность сторон',
        },
        {
          id: 7,
          text: 'Разрешение споров',
        },
        {
          id: 8,
          text: 'Дополнительные условия',
        },
        {
          id: 9,
          text: 'Реквизиты оператора персональных данных',
        },
      ];
    }

    this.getData()
  },

  mounted() {
    window.addEventListener('scroll', this.checkScrollPage)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.checkScrollPage)
  },

  watch: {},

  methods: {
    // отслеживание скроллинга страницы
    checkScrollPage() {
      let scroll = window.pageYOffset || document.documentElement.scrollTop;

      if (!this.scrollActive) {
        this.scrollActive = true;
      }

      setTimeout(() => {
        if ((window.pageYOffset || document.documentElement.scrollTop) === scroll) {
          let maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;

          if (maxScroll < 0) maxScroll = 0;

          this.documentScroll = scroll;

          if ((maxScroll - 1) >= scroll) {
            this.setNewActiveItemMenu();
          }
        }
      }, 250)
    },

    // установить новый активный элемент меню
    setNewActiveItemMenu() {

      for (let i = 0; i < this.items.length; i++) {
        let elem = document.getElementById('contract_p_' + this.items[i].id);

        if (elem) {
          let elemTopPosition = elem.getBoundingClientRect().top;
          let elemHeight = elem.offsetHeight;

          if (elemTopPosition > (-elemHeight + 100)) {
            this.indexActiveItems = i;
            break;
          }
        }
      }
    },

    // перейти на главную страницу
    goMainPage() {
      //this.$router.push({name: 'main', params: {}});
      location.href = 'https://ri-ege.ru/';
    },

    getData() {

      if (this.$route.name === 'contract') {
        let data = {
          module: 'get_course_contract_text'
        };

        send
          .post("unregistered.php", data)
          .then((response) => {
            console.log("Успех", response)
            this.contract = response.data.html;
            this.dataReceived = true;

            //  console.log(this.contract)
          })
          .catch((error) => {
            console.log("error")
            console.log(error.response)
            console.log(error)
          })
      } else if (this.$route.name === 'personalData') {

        let data = {
          module: 'get_course_personal_data_text'
        };

        send
          .post("unregistered.php", data)
          .then((response) => {
            console.log("Успех", response)
            this.contract = response.data.html;
            this.dataReceived = true;

          })
          .catch((error) => {
            console.log("error")
            console.log(error.response)
            console.log(error)
          })
      } else if (this.$route.name === 'license') {
        this.dataReceived = true;

      }
    },
    gotoElem(item, id, i) {
      VueScrollTo.scrollTo('#contract_p_' + id, 500, {offset: -100});

      this.indexActiveItems = i;
    },
  },

  computed: {

  },

  components: {
    WaitingLoading
  }
}
</script>

<style lang="scss" scoped>
$font_Geometria: Geometria, 'Geometria';

$color_background: #F6F6FB;
$color_Gray_1: #C5C5C9;
$color_Gray_2: #ABABB2;
$color_Gray_3: #8D909F;
$color_Gray_4: #EBEBEB;
$color_Gray_5: #F2F2F2;
$color_Gray_6: #F9F9F9;
$color_black: #333333;
$color_purple: #8C71FE;
$color_purple_2: #F2F1FF;
$color_purple_3: #E2E2F6;
$color_purple_4: #7B61EB;
$color_purple_5: #6F57D6;
$color_purple_6: #B1A1F2;
$color_red: #FF6597;
$color_green: #89CE54;
$color_grey: #AEBCD6;
$color_cyan: #C0CADC;
$color_cyan_2: #AEBCD6;
$color_cyan_3: #D1DBED;

$color_whiteHover: #fafafa;

$transition_medium: 0.5s ease;
$transition_fast: 0.25s ease;

.fade-enter-active, .fade-leave-active {
  transition: all ease .25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.block__column {
  display: flex;
  flex-direction: column;
}

.license_container {
  width: 100%;
  margin-top: 48px;
}

.license_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 128px);
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 64px;
  background-color: $color_whiteHover;

  .image {
    width: 800px;
    height: auto;
  }
}

#contract {
  padding: 72px 176px 60px 176px;

  .contract_main__style_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.contract {
  margin: 0 auto;
  font-family: $font_Geometria;

  .contract_main {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 28px;
    margin-top: 48px;
  }

  .contract_main_info {

    width: 100%;
    display: flex;
    justify-content: center;

  }

  .contract_main_panel {


    .contract_main_panel_block {
      position: sticky;
      top: 20px;
      width: 100%;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      padding: 40px 40px;
      border-radius: 16px;
      margin-left: 0px;

      .contract_main_panel_check {
        display: grid;
        grid-template-columns: 28px minmax(0, 1fr);
        grid-gap: 12px;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 22px;
        }

        .circle {
          width: 28px;
          height: 28px;
          border: 1px solid #C0CADC;
          box-sizing: border-box;
          border-radius: 50%;
        }

        .text {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $color_black;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }


  }
}

.svg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  svg {
    transition: $transition_medium;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backcourse {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #8C71FE;
  transition: $transition_medium;

  .header_text {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-decoration: none;
    transition: $transition_medium;

  }
}

.backcourse:hover a svg path {
  fill: #7B61EB;
}

.header_text:hover {
  color: #7B61EB;
}

@media all and (max-width: 1600px) {
  #contract {
    padding: 64px 104px 60px 104px;
  }

  .logo svg {
    width: 111px;
    height: auto;
  }

  .contract .contract_main {
    grid-gap: 24px;
    margin-top: 42px;
  }

  .license_container {
    margin-top: 42px;
  }

  .backcourse .header_text {
    font-size: 17px;
    line-height: 21px;
  }

  .contract .contract_main_panel .contract_main_panel_block {
    padding: 32px;
  }
}

@media all and (max-width: 1400px) {

  .license_image {
    width: calc(100% - 96px);
    border-radius: 10px;
    padding: 48px;

    .image {
      width: 700px;
      height: auto;
    }
  }

  .contract {

    .contract_main_info {

      width: 100%;

      .contract_main_info_header {

        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 120%;
          display: flex;
          align-items: center;
          color: #333333;

        }

        .subtitle {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          padding-top: 9px;
          color: #6E798C;

        }
      }


      .general_position {

        .general_position_header {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 120%;
          /* or 26px */

          display: flex;
          align-items: center;

          /* Black */
          margin-bottom: 21px;
          color: #333333;

        }

        .info {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 19px;
          color: #6E798C;
        }

        .text {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $color_black;

          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }

    .contract_main_panel {

      .contract_main_panel_block {
        .contract_main_panel_check {
          .text {
            font-size: 14px;

          }
        }
      }
    }
  }

  #contract {
    padding: 60px 93px 60px 93px;
  }

  .contract .contract_main {
    grid-gap: 20px;
    margin-top: 38px;
  }

  .license_container {
    margin-top: 38px;
  }

  .contract .contract_main_panel .contract_main_panel_block .contract_main_panel_check:not(:last-child) {
    margin-bottom: 18px;
  }

  .contract .contract_main_panel .contract_main_panel_block {
    padding: 26px;
    border-radius: 10px;
  }

  .contract .contract_main_panel .contract_main_panel_block .contract_main_panel_check {
    grid-template-columns: 26px minmax(0, 1fr);
    grid-gap: 10px;
  }

  .contract_main_panel_check svg {
    width: 26px;
    height: 26px;
  }

  .contract .contract_main_panel .contract_main_panel_block .contract_main_panel_check .circle {
    width: 26px;
    height: 26px;
  }

  .backcourse .header_text {
    font-size: 16px;
    line-height: 20px;
  }

  .logo svg {
    width: 108px;
    height: auto;
  }

  .backcourse .svg {
    margin-right: 4px;
  }

  .backcourse .svg svg {
    width: 18px;
    height: 18px;
  }
}

@media all and (max-width: 1199px) {
  .contract .contract_main_panel .contract_main_panel_block .contract_main_panel_check:not(:last-child) {
    margin-bottom: 16px;
  }

  .contract_main_panel_check svg {
    width: 24px;
    height: 24px;
  }

  .contract .contract_main_panel .contract_main_panel_block .contract_main_panel_check .circle {
    width: 24px;
    height: 24px;
  }

  .contract .contract_main {
    grid-gap: 18px;
    margin-top: 34px;
  }

  .license_container {
    margin-top: 34px;
  }

  .logo svg {
    width: 101px;
    height: auto;
  }

  .backcourse .header_text {
    font-size: 15px;
    line-height: 19px;
  }

  .backcourse .svg svg {
    width: 18px;
    height: 18px;
  }

  .backcourse .svg {
    margin-right: 2px;
  }

  #contract {
    padding: 60px 60px 60px 60px;
  }

  .contract_main_panel {
    display: none;
  }

  .contract .contract_main {
    grid-template-columns: 1fr;
  }

  #contract .info div {
    padding: 24px;
  }

  #contract .contract_block {
    padding: 24px;
  }
}

@media all and (max-width: 899px) {
  #contract {
    padding: 52px 30px 30px 30px;
  }

  .backcourse .header_text {
    font-size: 14px;
    line-height: 18px;
  }

  .backcourse .svg svg {
    width: 16px;
    height: 16px;
  }

  .contract .contract_main {
    margin-top: 25px;
  }

  .license_container {
    margin-top: 25px;
  }

  .license_image {
    width: calc(100% - 60px);
    border-radius: 10px;
    padding: 30px;

    .image {
      width: 100%;
      height: auto;
    }
  }
}


@media all and (max-width: 599px) {
  #contract {
    padding: 32px 16px 30px 16px;
  }

  .license_image {
    width: calc(100% - 30px);
    border-radius: 8px;
    padding: 15px;

    .image {
      width: 100%;
      height: auto;
    }
  }

  .logo svg {
    width: 87px;
    height: auto;
  }

  .contract .contract_main_info .general_position .info {
    font-size: 14px;
    line-height: 18px;
  }
}

</style>