<template>
  <div class="loading_container"
       :class="{
        'loading_container__params_largeMargin': isLoadedForAllPage
       }"
  >
    <transition name="fadeIn">
      <div class="loading_text"
           v-if="showPreloadText"
      >
        {{activeTextLoading}}
      </div>
    </transition>
  </div>
</template>

<script>
  let timerElem, timerGetServerAnswer, timerCheckNetworkError;

  export default {
    name: "WaitingLoading",
    props: {
      showComponent: {
        type: Boolean,
        default: () => {
          return false
        }
      },

      isLoadedForAllPage: {  // компонент для используется для загрузки всей страницы?
        type: Boolean,
        default: () => {
          return false
        }
      },
    },

    data() {
      return {
        showPreloadText: false,
        duration: 0.5, // задержка в с.
        currentPage: this.$route.name,
        activeTextLoading: 'Пожалуйста, подождите, выполняется загрузка...',
        textLoading: {
          inProgress: 'Пожалуйста, подождите, выполняется загрузка...',
          slowInternet: 'Неустойчивое интернет-соединение. Попробуйте сменить точку доступа к сети.',
          networkError: 'Нет соединения с интернетом. Перезагрузите страницу и попробуйте снова.',
        },
        durationSlowInternet: 10, // задержка для отображения ошибки "Неустойчивое интернет-соединение"
        blockMethodStartCheckTimingQuery: false,
      }
    },

    created() {

    },

    mounted() {
      if (this.showComponent) {
        timerElem = setTimeout(() => {
          this.showPreloadText = true;
        }, this.duration * 1000)
      } else {
        clearTimeout(timerElem);
        this.showPreloadText = false;
      }
    },

    watch: {
      'showComponent'(after) {
        if (after) {
          this.startCheckTimingQuery()
        } else {
          this.clearCheckTimingQuery()
        }
        
      },

      '$route'(after, before) {
        if (after.name !== before.name) {
          this.currentPage = after.name;
        }

        if (this.showComponent && !this.blockMethodStartCheckTimingQuery) {
          this.clearCheckTimingQuery();

          this.$nextTick(() => {
            this.startCheckTimingQuery();
          })
        }
      },
    },

    beforeUnmount() {
      this.clearCheckTimingQuery()
    },

    methods: {
      // начать слежение за состоянием запросов
      startCheckTimingQuery() {
        this.blockMethodStartCheckTimingQuery = true;

        setTimeout(() => {
          this.blockMethodStartCheckTimingQuery = false;
        }, 100);

        timerElem = setTimeout(() => {
          this.showPreloadText = true;
        }, this.duration * 1000);

        timerCheckNetworkError = setTimeout(() => {
          if (this.$store.getters.getNetworkError) { // ошибка подключения
            this.activeTextLoading = this.textLoading.networkError;
          } else {
            timerGetServerAnswer = setTimeout(() => {
              if (this.showComponent) {
                this.activeTextLoading = this.textLoading.slowInternet;
              }
            }, this.durationSlowInternet * 1000);
          }
        }, 1000);
      },

      // очистить все Timeout, отвечающие за слежение за состояниями запросов
      clearCheckTimingQuery() {
        clearTimeout(timerElem);
        clearTimeout(timerCheckNetworkError);
        clearTimeout(timerGetServerAnswer);
        this.showPreloadText = false;

        this.$nextTick(() => {
          this.activeTextLoading = this.textLoading.inProgress;
        })
      },
    },

    computed: {

    },
  }
</script>

<style lang="scss" scoped>
  $font_Geometria: Geometria, 'Geometria';

  $color_background: #F6F6FB;
  $color_Gray_1: #C5C5C9;
  $color_Gray_2: #ABABB2;
  $color_Gray_3: #8D909F;
  $color_Gray_4: #EBEBEB;
  $color_Gray_5: #F2F2F2;
  $color_Gray_6: #F9F9F9;
  $color_black: #333333;
  $color_purple: #8C71FE;
  $color_purple_2: #F2F1FF;
  $color_purple_3: #E2E2F6;
  $color_purple_4: #7B61EB;
  $color_purple_5: #6F57D6;
  $color_purple_6: #B1A1F2;
  $color_red: #FF6597;
  $color_green: #89CE54;
  $color_grey: #AEBCD6;
  $color_cyan: #C0CADC;
  $color_cyan_2: #AEBCD6;

  $color_whiteHover: #fafafa;

  $transition_medium: 0.5s ease;
  $transition_fast: 0.25s ease;

  $path_img_checkbox: '/lk/megazabeg//img/save.svg';

  .loading_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .loading_container__params_largeMargin {
    margin: 80px 0;
  }

  .loading_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 28px 34px;
    background-color: rgba(255, 255, 255, 0); // $color_background
    color: $color_cyan_2;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    border-radius: 4px;
  }

  @media all and (max-width: 1800px) {

  }

  @media all and (max-width: 1600px) {
    .loading_text {
      padding: 26px 32px;
      font-size: 16px;
      line-height: 20px;
    }

    .loading_container__params_largeMargin {
      margin: 60px 0;
    }
  }

  @media all and (max-width: 1440px) {
    .loading_container__params_largeMargin {
      margin: 50px 0;
    }
  }

  @media all and (max-width: 1280px) {
    .loading_text {
      padding: 20px 24px;
      font-size: 15px;
      line-height: 19px;
    }

    .loading_container__params_largeMargin {
      margin: 40px 0;
    }
  }

  @media all and (max-width: 1160px) {
    .loading_text {
      padding: 26px 32px;
      font-size: 16px;
      line-height: 20px;
    }

    .loading_container__params_largeMargin {
      margin: 50px 0;
    }
  }

  @media all and (max-width: 960px) {
    .loading_container__params_largeMargin {
      margin: 40px 0;
    }
  }

  @media all and (max-width: 600px) {
    .loading_text {
      padding: 20px 24px;
      font-size: 15px;
      line-height: 19px;
    }

    .loading_container__params_largeMargin {
      margin: 44px 0;
    }
  }
</style>