import {reactive} from "vue";

let ManagementCookie = () => {
  let cookies = reactive({
    show: false,
    text: 'Пользуясь нашим сайтом, вы соглашаетесь с тем, что <a href="https://ri-ege.ru/lk/gk/cookiescontract" target="_blank">мы используем cookies</a>',
    type: 'cookies'
  });

  // закрыть модальное окно отображения использования куки
  let closeModalNotificationCookies = () => {
    cookies.show = false;

    if (!checkAcceptCookies()) {
      localStorage.setItem('acceptCookies', JSON.stringify(true));
    }
  };

  // проверить приняты ли куки
  let checkAcceptCookies = () => {
    let acceptCookies = JSON.parse(localStorage.getItem('acceptCookies'))
    if (acceptCookies) return true;
    return false;
  };

  if (!checkAcceptCookies()) cookies.show = true;

  return {
    cookies,
    closeModalNotificationCookies,
  }
};

export default ManagementCookie;