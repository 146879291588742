import {onMounted, onUnmounted, inject} from "vue";

let ManagementWindow = () => {
  const emitter = inject('emitter');

  // вызывается при изменении отображения видимости текущей вкладки
  let windowVisibilityChange = () => {
    emitter.emit('windowVisibilityChange', document.visibilityState)
  };

  onMounted(() => {
    document.addEventListener("visibilitychange", windowVisibilityChange);
  });

 onUnmounted(() => {
   document.removeEventListener("visibilitychange", windowVisibilityChange);
 })
};

export default ManagementWindow