<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Репетиторская империя` : `Репетиторская империя` }}</template>
  </metainfo>

 <div id="app"
      class="container container__large"
 >
   <transition name="fade">
     <ModalNotification
       v-if="cookies.show"
       :timeShowNotification="10"
       :textNotification="cookies.text"
       :typeNotification="cookies.type"
       @close="closeModalNotificationCookies"
     >
     </ModalNotification>
   </transition>

   <div class="text_loaded" id="fonts_loaded">Текст для проверки загрузки шрифтов</div>

<!--   <ScrollTop></ScrollTop>-->

   <div class="block block__column container_component container_component__theme_large"
        v-if="pagesWithoutMenu.includes(currentRoute)"
   >
      <router-view class="default_component"></router-view>
   </div>

   <div class="block block__column"
        v-else
   >
     <HeaderComponent></HeaderComponent>
     <router-view class="main_component"></router-view>
     <FooterComponent></FooterComponent>
   </div>
 </div>
</template>

<script>
import HeaderComponent from "@/components/DefaultComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "@/components/DefaultComponents/FooterComponent/FooterComponent";
import ModalNotification from "./components/DefaultComponents/ModalWindows/ModalNotification";
//import ScrollTop from "./components/DefaultComponents/ScrollTopComponent/ScrollTop";
import { useMeta } from 'vue-meta';

import ManagementLoadedFonts from './AppComposables/ManagementLoadedFonts';
import ManagementWindow from './AppComposables/ManagementWindow';
import ManagementRouter from './AppComposables/ManagementRouter';
import ManagementUtm from './AppComposables/ManagementUtm';
import ManagementCookie from './AppComposables/ManagementCookie';

export default {
  name: 'App',

  setup() {
    useMeta({
      meta: [{
        vmid: 'robots',
        name: 'robots',
        content: null,
        //content: 'noindex, nofollow',
      }]
    })

    let {
      cookies,
      closeModalNotificationCookies
    } = ManagementCookie();

    let {
      pagesWithoutMenu,
      currentRoute,
    } = ManagementRouter();

    let {
      loadedFonts
    } = ManagementLoadedFonts();

    ManagementWindow();
    ManagementUtm();

    return {
      cookies,
      loadedFonts,
      pagesWithoutMenu,
      currentRoute,
      closeModalNotificationCookies,
    }
  },

  components: {
    HeaderComponent,
    FooterComponent,
    ModalNotification,
    //ScrollTop
  }
}
</script>

<style lang="scss">
@import "./styles/style_default.scss";
@import "./styles/styles_fonts.scss";
</style>

<style scoped lang="scss">
@import "./styles/variables";

#app {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: $color_white;
  font-family: $font_Geometria;
  color: $color_black;
}

.text_loaded {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  position: absolute;
  left: -9999px;
  top: -9999px;
  opacity: 0;
  z-index: -1;
  font-family: $font_Geometria;
  font-size: 16px;
  line-height: 20px;
}

.container_component__theme_large {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: $color_purple_7;
}

.default_component {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

</style>
