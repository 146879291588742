import {reactive, inject} from 'vue';
import {footerImages} from '../../../../svgStore/svgStoreFiles';

let ManagementFooter = () => {
  const VueScrollTo = inject('VueScrollTo');
  let socialLink = reactive({ // наши соц. сети
    'vk': {
      id: 0,
      image: footerImages.vkLogo,
      link: 'https://vk.com/egeneproblema'
    },
    'telegram': {
      id: 1,
      image: footerImages.telegramLogo,
      link: 'https://t.me/histandsoc_ri'
    },
    'youtube': {
      id: 2,
      image: footerImages.youtubeLogo,
      link: 'https://www.youtube.com/c/Репетиторскаяимперия'
    },
    'yandexZen': {
      id: 3,
      image: footerImages.yandexZenLogo,
      link: 'https://zen.yandex.ru/id/5f187f2e4501c50d5aa4e806'
    },
    'tiktok': {
      id: 4,
      image: footerImages.tiktokLogo,
      link: 'https://www.tiktok.com/@riege.ru'
    },
  });

  const siteNavigation = [
    {
      id: 0,
      title: 'Карта сайта',
      data: [ // страницы сайта
        {
          id: 0,
          title: 'Личный кабинет',
          link: 'https://ri-ege.ru/lk/gk/profile',
          routerParams: {name: '', params: {}}
        },
        {
          id: 1,
          title: 'Главная',
          link: undefined,
          routerParams: {name: 'main', params: {}}
        },
        {
          id: 2,
          title: 'О компании',
          link: 'https://ri-ege.ru/about/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 3,
          title: 'Сотрудничество',
          link: 'https://vk.com/riege_hr',
          routerParams: {name: '', params: {}}
        },
        {
          id: 4,
          title: 'Статьи',
          link: 'https://zen.yandex.ru/id/5f187f2e4501c50d5aa4e806?lang=ru',
          routerParams: {name: '', params: {}}
        }
      ]
    },
    {
      id: 1,
      title: 'Предметы',
      data: [ // предметы в РИ
        {
          id: 4,
          title: 'Русский язык',
          link: 'https://ri-ege.ru/subjects/russian/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 2,
          title: 'Обществознание',
          link: 'https://ri-ege.ru/subjects/society/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 6,
          title: 'Математика',
          link: 'https://ri-ege.ru/subjects/mathematics/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 1,
          title: 'История',
          link: 'https://ri-ege.ru/subjects/history/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 5,
          title: 'Английский язык',
          link: 'https://ri-ege.ru/subjects/english/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 3,
          title: 'Литература',
          link: 'https://ri-ege.ru/subjects/literature/',
          routerParams: {name: '', params: {}}
        },
      ]
    },
    {
      id: 2,
      title: 'Курсы',
      data: [ // наши курсы
        {
          id: 0,
          title: 'Годовой курс ЕГЭ и ОГЭ',
          link: 'https://ri-ege.ru/lk/gk/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 1,
          title: 'Пробный экзамен ЕГЭ',
          link: 'https://go.ri-ege.ru/srez',
          routerParams: {name: '', params: {}}
        },
        {
          id: 2,
          title: 'Спецкурсы ЕГЭ',
          link: 'https://go.ri-ege.ru/speckurs',
          routerParams: {name: '', params: {}}
        },
        {
          id: 3,
          title: 'Бесплатный урок',
          link: '',
          routerParams: {name: '', params: {}},
          scrollBlock: 'free_lesson', // id блока для скроллинга
        },
      ]
    }
  ]

  const contacts = [ // наши контакты
    {
      id: 0,
      title: '8 (800) 101-07-79',
      type: 'phone'
    },
    {
      id: 1,
      title: 'info@ri-ege.ru',
      type: 'email'
    },
  ];

  const documentsInfo = [
    {
      id: 0,
      title: 'ИП Шкурский Виктор Александрович',
      link: undefined
    },
    {
      id: 1,
      title: 'ИНН 723010601463',
      link: undefined
    },
    {
      id: 2,
      title: 'ОГРНИП 316723200100018',
      link: undefined
    },
    {
      id: 3,
      title: 'Лицензия на образовательную деятельность',
      link: {name: 'license', params: {}}
    },
    {
      id: 4,
      title: 'Политика конфиденциальности',
      link: {name: 'personalData', params: {}}
    },
    {
      id: 5,
      title: 'Договор оферты',
      link: {name: 'contract', params: {}}
    },
    {
      id: 6,
      title: 'Правовая информация',
      link: {name: 'legalInformation', params: {}}
    },
    {
      id: 7,
      title: '© Репетиторская империя, </br>2011 – ' + new Date().getFullYear(),
      link: undefined
    },
  ];

  let contact = (data, type) => { // связаться с РИ
    switch (type) {
      case 'phone':
        window.location.href = 'tel:' + data;
        break;

      case 'email':
        window.location.href = 'mailto:' + data;
        break;
    }
  }

  // проскролить контент к блоку
  let scrollTo = (idBlock) => {
    if (idBlock && (idBlock.length > 0)) {
      let headerBlockHeight = document.getElementById('header').offsetHeight;
      VueScrollTo.scrollTo('#' + idBlock, 1000, {offset: -(headerBlockHeight * 2)});
    }
  }

  return {
    socialLink,
    siteNavigation,
    contacts,
    documentsInfo,
    contact,
    scrollTo,
  }
};

export default ManagementFooter