<template>
  <div class="content">
    <div class="title">
      {{ title }}
    </div>
    <div class="main">
      <div class="points"
           v-for="(item, i) in points"
           :key="i"
           :id="'block_' + i"
      >
        <div class="text text__default"
             v-if="type === 'string'"
             v-html="item.text"
        ></div>

        <div class="text text__link"
            v-else-if="type === 'link'"
        >
          <a class="link" :href="item.link" download target="_blank">
            <div class="svg margin_rigth svg_tablet">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.2786 11.0883C9.47541 9.87293 11.5627 9.87293 12.7595 11.0883L13.5064 11.8467L15 10.3299L14.2532 9.5715C13.2571 8.55887 11.9303 8 10.5191 8C9.10781 8 7.78107 8.55887 6.78495 9.5715L4.54343 11.8467C3.55502 12.8536 3 14.2174 3 15.6392C3 17.061 3.55502 18.4248 4.54343 19.4317C5.03343 19.93 5.61556 20.3251 6.25638 20.5942C6.8972 20.8634 7.58408 21.0013 8.27754 21C8.97119 21.0015 9.65828 20.8637 10.2993 20.5945C10.9403 20.3254 11.5226 19.9302 12.0127 19.4317L12.7595 18.6733L11.2659 17.1565L10.5191 17.9149C9.92379 18.5167 9.11803 18.8546 8.27807 18.8546C7.43811 18.8546 6.63234 18.5167 6.03707 17.9149C5.44395 17.3107 5.11089 16.4923 5.11089 15.6392C5.11089 14.786 5.44395 13.9677 6.03707 13.3635L8.2786 11.0883Z" fill="#7B61EB"/>
                <path d="M11.9873 4.56824L11.2405 5.32663L12.7341 6.84343L13.4809 6.08503C14.0762 5.48324 14.882 5.14538 15.7219 5.14538C16.5619 5.14538 17.3677 5.48324 17.9629 6.08503C18.5561 6.68925 18.8891 7.50761 18.8891 8.36077C18.8891 9.21392 18.5561 10.0323 17.9629 10.6365L15.7214 12.9117C14.5246 14.1271 12.4373 14.1271 11.2405 12.9117L10.4936 12.1533L9 13.6701L9.74682 14.4285C10.7429 15.4411 12.0697 16 13.4809 16C14.8922 16 16.2189 15.4411 17.215 14.4285L19.4566 12.1533C20.445 11.1463 21 9.78254 21 8.36077C21 6.93899 20.445 5.5752 19.4566 4.56824C18.4653 3.56398 17.1222 3 15.7219 3C14.3217 3 12.9786 3.56398 11.9873 4.56824Z" fill="#7B61EB"/>
              </svg>
            </div>
            {{ (i + 1) + '. ' + item.text }}
            <div class="svg margin_left svg_desctop">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.2786 11.0883C9.47541 9.87293 11.5627 9.87293 12.7595 11.0883L13.5064 11.8467L15 10.3299L14.2532 9.5715C13.2571 8.55887 11.9303 8 10.5191 8C9.10781 8 7.78107 8.55887 6.78495 9.5715L4.54343 11.8467C3.55502 12.8536 3 14.2174 3 15.6392C3 17.061 3.55502 18.4248 4.54343 19.4317C5.03343 19.93 5.61556 20.3251 6.25638 20.5942C6.8972 20.8634 7.58408 21.0013 8.27754 21C8.97119 21.0015 9.65828 20.8637 10.2993 20.5945C10.9403 20.3254 11.5226 19.9302 12.0127 19.4317L12.7595 18.6733L11.2659 17.1565L10.5191 17.9149C9.92379 18.5167 9.11803 18.8546 8.27807 18.8546C7.43811 18.8546 6.63234 18.5167 6.03707 17.9149C5.44395 17.3107 5.11089 16.4923 5.11089 15.6392C5.11089 14.786 5.44395 13.9677 6.03707 13.3635L8.2786 11.0883Z" fill="#7B61EB"/>
                <path d="M11.9873 4.56824L11.2405 5.32663L12.7341 6.84343L13.4809 6.08503C14.0762 5.48324 14.882 5.14538 15.7219 5.14538C16.5619 5.14538 17.3677 5.48324 17.9629 6.08503C18.5561 6.68925 18.8891 7.50761 18.8891 8.36077C18.8891 9.21392 18.5561 10.0323 17.9629 10.6365L15.7214 12.9117C14.5246 14.1271 12.4373 14.1271 11.2405 12.9117L10.4936 12.1533L9 13.6701L9.74682 14.4285C10.7429 15.4411 12.0697 16 13.4809 16C14.8922 16 16.2189 15.4411 17.215 14.4285L19.4566 12.1533C20.445 11.1463 21 9.78254 21 8.36077C21 6.93899 20.445 5.5752 19.4566 4.56824C18.4653 3.56398 17.1222 3 15.7219 3C14.3217 3 12.9786 3.56398 11.9873 4.56824Z" fill="#7B61EB"/>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type: String,
      default: ''
    },
    points:{
      type: Array,
      default: () => {
        return []
      }
    },
    type:{
      type: String,
      default: null
    }
  },
  name: "InfoBlock"
}
</script>

<style lang="scss" scoped>
@import "../../../../../styles/variables";

.svg_desctop{
  display: flex;
}
.svg_tablet{
  display: none;
}
.svg{
  align-items: flex-end;
}
.content{
  background: $color_white;
  border: 1px solid $color_grey_5;
  border-radius: 16px;
}
.title{
  padding: 20px 32px;
  border-bottom: 1px solid $color_grey_5;
  font-family: $font_Geometria;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: $color_black;
}
.main{
  padding: 21px 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.points{
  font-family: $font_Geometria;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $color_black;
}
.link{
  font-family: $font_Geometria;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $color_black;
  text-decoration: none;
  transition: all 0.5s;
  display: flex;
  align-items: center;
}
.link:hover{
  color: $color_purple_3;
  text-decoration: underline;
}
.link_block{
  display: flex;
}
.margin_rigth{
  margin-right: 5px;
}
.margin_left{
  margin-left: 5px;
}
@media all and (max-width: 1600px) {
  .content{
    border-radius: 16px;
  }
  .title{
    padding: 18px 30px;
    font-size: 15px;
    line-height: 18px;
  }
  .main{
    padding: 21px 35px;
  }
}
@media all and (max-width: 1440px) {
  .content{
    border-radius: 10px;
  }
  .title{
    padding: 16px 28px;
    font-size: 14px;
    line-height: 18px;
  }
  .main{
    grid-gap: 18px;
    padding: 21px 30px;
  }
  .points{
    font-size: 15px;
  }
  .link{
    font-size: 15px;
  }
}
@media all and (max-width: 1280px) {
  .title{
    padding: 14px 24px;
  }
  .content{
    border-radius: 8px;
  }
  .main{
    grid-gap: 18px;
    padding: 21px 24px;
  }

  .link .svg svg {
    width: 22px;
    height: 22px;
  }
}
@media all and (max-width: 1160px) {
  .title{
    font-size: 13px;
    line-height: 16px;
  }
  .svg_desctop{
    display: none;
  }
  .svg_tablet{
    display: flex;
  }
}
@media all and (max-width: 600px) {
  .title{
    padding: 14px 16px;
    font-size: 13px;
    line-height: 16px;
  }
  .main{
    grid-gap: 16px;
    padding: 16px;
  }
  .points{
    font-size: 14px;
    line-height: 18px;
  }
  .link{
    font-size: 14px;
    line-height: 18px;
  }
  .svg_tablet{
    height: 100%;
    display: flex;
  }

  .link .svg svg {
    width: 20px;
    height: 20px;
  }
}


</style>