import { createStore } from 'vuex';

export const store = createStore({
  state() {
    return {
      fontsIsLoaded: false, // шрифты загружены?
    }
  },

  mutations: {
    setFontsIsLoaded(state, payload) {
      state.fontsIsLoaded = payload
    },
  }
})