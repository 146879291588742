<template>
  <div class="default_component"
       id="page_notFound"
  >
    <div class="default_block">
      <div class="block_container">
        <div class="svg_container"
             v-html="svgItem"
        ></div>

        <h2>Страница не найдена</h2>

        <span>То, что вы ищете, не существует или пока не открыто, обратитесь к <a href="https://vk.com/riege_help" target="_blank">тех. специалисту</a> или вернитесь на главную</span>

        <div class="button button__type_main"
             @click="goToMainPage()"
        >Вернуться на главную</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { image404 } from '../../../svgStore/svgStoreFiles.js';
  //import {useRouter} from "vue-router";
  import { useMeta } from 'vue-meta';

  export default {
    name: "Page404",

    setup() {
      useMeta({
        meta: [{
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow',
        }]
      })

      //const router = useRouter();
      const svgItem = image404;

      let goToMainPage = () => { // перейти на главную страницу
        location.href = 'https://ri-ege.ru/';
      }

      return {
        svgItem,
        goToMainPage
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "../../../styles/variables";

  $font_Geometria: Geometria, 'Geometria';

  $color_background: #F6F6FB;
  $color_white: #fff;
  $color_Gray_1: #C5C5C9;
  $color_Gray_2: #ABABB2;
  $color_Gray_3: #8D909F;
  $color_Gray_4: #EBEBEB;
  $color_Gray_5: #F2F2F2;
  $color_Gray_6: #F9F9F9;
  $color_Gray_7: #6E798C;
  $color_black: #333333;
  $color_purple: #8C71FE;
  $color_purple_2: #F2F1FF;
  $color_purple_3: #E2E2F6;
  $color_purple_4: #7B61EB;
  $color_purple_5: #6F57D6;
  $color_purple_6: #B1A1F2;
  $color_red: #FF6597;
  $color_green: #89CE54;
  $color_grey: #AEBCD6;
  $color_cyan: #C0CADC;
  $color_cyan_2: #AEBCD6;
  $color_cyan_3: #D1DBED;

  $color_whiteHover: #fafafa;

  $transition_medium: 0.5s ease;
  $transition_fast: 0.25s ease;

  .default_component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .default_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 960px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin: 40px;
  }

  .block_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 44px 44px 44px;

    h2 {
      padding: 0;
      margin: 13px 0 0 0;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 120%;
      text-align: center;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 135%;
      text-align: center;
      margin-top: 8px;
      width: 500px;
    }

    a {
      color: $color_purple;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: $transition_medium;
  }

  .button__type_main {
    width: fit-content;
    height: 48px;
    padding: 0 24px;
    border-radius: 8px;
    background-color: $color_purple;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-top: 14px;

    &:hover {
      background-color: $color_purple_4;
    }
  }

  @media all and (max-width: 1600px) {
    .default_block {
      min-width: 900px;
    }

    .block_container {
      padding: 0 40px 40px 40px;

      h2 {
        margin: 20px 0 0 0;
        font-size: 24px;
        line-height: 120%;
      }

      span {
        font-size: 16px;
        line-height: 135%;
        margin-top: 6px;
      }
    }

    .button__type_main {
      height: 46px;
      padding: 0 22px;
      font-size: 15px;
      line-height: 19px;
      margin-top: 12px;
    }
  }

  @media all and (max-width: 1440px) {
    .default_block {
      min-width: 820px;
      border-radius: 10px;
    }

    .block_container {
      padding: 0 36px 36px 36px;

      h2 {
        font-size: 22px;
        line-height: 120%;
      }

      span {
        font-size: 15px;
        line-height: 19px;
        margin-top: 5px;
        width: 440px;
      }
    }

    .button__type_main {
      height: 42px;
      padding: 0 20px;
      font-size: 14px;
      line-height: 18px;
      margin-top: 10px;
      border-radius: 6px;
    }
  }

  @media all and (max-width: 1280px) {
    .default_block {
      min-width: 720px;
      border-radius: 8px;
    }

    .block_container {
      padding: 0 32px 32px 32px;

      h2 {
        font-size: 20px;
        line-height: 120%;
      }

      span {
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
        width: 420px;
      }
    }

    .button__type_main {
      height: 40px;
      padding: 0 20px;
      font-size: 13px;
      line-height: 17px;
      margin-top: 10px;
    }
  }

  @media all and (max-width: 1160px) {

  }

  @media all and (max-width: 960px) {
    .default_block {
      width: calc(100% - 60px);
      min-width: auto;
      max-width: 720px;
      margin: 40px 0;
    }
  }

  @media all and (max-width: 600px) {
    .default_block {
      width: calc(100% - 30px);
      min-width: auto;
      max-width: 720px;
      margin: 30px 0;
    }

    .block_container {
      padding: 0 15px 20px 15px;

      h2 {
        font-size: 18px;
        line-height: 120%;
      }

      span {
        font-size: 13px;
        line-height: 16px;
        margin-top: 4px;
        width: 100%;
      }
    }

    .button__type_main {
      height: 40px;
      padding: 0 20px;
      font-size: 13px;
      line-height: 17px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }

  @media all and (max-width: 380px) {

  }

  @media all and (max-width: 360px) {
    
  }

</style>