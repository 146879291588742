import {useRoute} from "vue-router";

let ManagementUtm = () => {
  let route = useRoute();
  let utmMark = {};

  if (route.query.utm_source) {
    utmMark['utm_source'] = route.query.utm_source;
  }

  if (Object.keys(utmMark).length > 0) localStorage.setItem('utmMark', JSON.stringify(utmMark));

  return {

  }
}

export default ManagementUtm