<template>
  <div class="footer_component">
    <div class="content">
      <div class="image logo__theme_logo block__tablet"
           v-html="logoPlatform"
      ></div>

      <div class="grid grid__theme_content">
        <div class="block block__column block__desktop">
          <div class="image logo__theme_logo"
               v-html="logoPlatform"
          ></div>

          <div class="social_container">
            <a class="social"
               v-for="item of socialLink"
               :key="'social_' + item.id"
               :href="item.link"
               target="_blank"
               v-html="item.image"
            ></a>
          </div>
        </div>

        <!--    Навигационные маршруты    -->
        <div class="block block__column navigation_item"
             :class="'navigation_item_' + (i + 1)"
             v-for="(elem, i) in siteNavigation"
             :key="'nav_' + elem.id"
        >
          <div class="text text__theme_title">{{ elem.title }}</div>
          <div class="list">
            <div class="list_item"
                 v-for="item in elem.data"
                 :key="'nav_' + elem.id + '_map_item_' + item.id"
            >
              <router-link :to="item.routerParams"
                           v-if="item.routerParams && (item.routerParams.name.length > 0)"
              >
                <div class="text text__theme_link">
                  {{item.title}}
                </div>
              </router-link>

              <a :href="item.link"
                 v-else-if="item.link && (item.link.length > 0)"
              >
                <div class="text text__theme_link">
                  {{item.title}}
                </div>
              </a>

              <a v-else-if="item.scrollBlock && (item.scrollBlock.length > 0)"
                 @click="scrollTo(item.scrollBlock)"
              >
                <div class="text text__theme_link">
                  {{item.title}}
                </div>
              </a>

              <div class="text"
                   v-else
              >
                {{item.title}}
              </div>
            </div>
          </div>
        </div>

        <!--    Контакты    -->
        <div class="block block__column navigation_item navigation_item_4">
          <div class="text text__theme_title">Контакты</div>
          <div class="list">
            <div class="list_item"
                 v-for="item in contacts"
                 :key="'contacts_' + item.id"
                 @click="contact(item.title, item.type)"
            >
              <div class="text text__theme_link"
                   @click="contact(item.title, item.type)"
              >
                {{item.title}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--    Ссылки на соц. сети для планшетов    -->
      <div class="block block__theme_social block__tablet">
        <h3>Мы в соцсетях!</h3>

        <div class="social_container">
          <a class="social"
             v-for="item of socialLink"
             :key="'social_' + item.id"
             :href="item.link"
             target="_blank"
             v-html="item.image"
          ></a>
        </div>
      </div>

      <div class="block block__column block__theme_documents">
        <div class="list">
          <div class="list_item"
               v-for="item in documentsInfo"
               :key="'documents_info_' + item.id"
          >
            <router-link :to="item.link"
                         v-if="item.link"
            >
              <div class="text text__theme_link"
                   v-html="item.title"
              ></div>
            </router-link>

            <div class="text"
                 v-else
                 v-html="item.title"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logoPlatform} from '../../../svgStore/svgStoreFiles';
import ManagementFooter from './Composables/ManagementFooter';

export default {
  name: "FooterComponent",

  setup() {
    let {
      socialLink,
      siteNavigation,
      contacts,
      documentsInfo,
      scrollTo,
      contact,
    } = ManagementFooter()

    return {
      logoPlatform,
      socialLink,
      siteNavigation,
      contacts,
      documentsInfo,
      scrollTo,
      contact,
    }
  },

  components: {

  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.footer_component .content .block__tablet {
  display: none;
}

.block {
  display: flex;
}

.block__column {
  display: flex;
  flex-direction: column;
}

.block__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

a {
  text-decoration: none;
}

.footer_component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 20px 41px 20px;
  background-color: $color_cyanite_6;
  overflow: hidden;
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 76px;
  width: 1294px;
}

.grid__theme_content {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: flex-start;
  grid-gap: 72px;
}

.logo__theme_logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
}

.social_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  grid-gap: 12px;
  margin-top: 32px;

  .social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background-color: $color_purple_3;
    border-radius: 4px;
    cursor: pointer;
    transition: $transition_medium;

    &:hover {
      background-color:  $color_purple_1;
    }
  }
}

.text__theme_title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.list {
  margin-top: 12px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 10px;

  .list_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &:hover .text__theme_link {
      cursor: pointer;
      color: $color_purple_3;
    }

    a {
      width: 100%;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 145%;
      color: $color_grey_2;
      transition: $transition_medium;
      width: 100%;
      cursor: default;
    }
  }
}

.block__theme_documents {
  .list {
    margin: 0;
    grid-gap: 12px;
    max-width: 254px;
  }

  .text__theme_link {
    text-decoration: underline;
  }
}

@media all and (min-width: 1400px) and (max-width: 1600px) {
  .content {
    grid-gap: 65px;
    width: 1220px;
  }

  .grid__theme_content {
    grid-gap: 65px;
  }

  .footer_component {
    padding: 50px 20px 44px 20px;
  }

  .social_container {
    grid-gap: 12px;
    margin-top: 28px;
  }

  .social_container .social {
    width: 40px;
    height: 40px;
  }

  .text__theme_title {
    font-size: 17px;
    line-height: 21px;
  }

  .list .list_item .text {
    font-size: 15px;
    line-height: 21px;
  }

  .block__theme_documents .list {
    grid-gap: 10px;
    max-width: 234px;
  }
}

@media all and (min-width: 1200px) and (max-width: 1399px) {
  .grid__theme_content {
    grid-gap: initial;
  }

  .logo__theme_logo {
    margin-top: -12px;
  }

  .content {
    grid-gap: initial;
    width: calc(100% - 120px);
  }

  .footer_component {
    padding: 52px 20px 46px 20px;
  }

  .social_container {
    grid-gap: 10px;
    margin-top: 26px;
    grid-template-columns: repeat(3, 38px);
  }

  .social_container .social {
    width: 38px;
    height: 38px;
  }

  .text__theme_title {
    font-size: 17px;
    line-height: 21px;
  }

  .list .list_item .text {
    font-size: 15px;
    line-height: 21px;
  }
}

// tablets
@media all and (max-width: 1199px) {
  .footer_component .content .block__desktop {
    display: none;
  }

  .footer_component .content .block__tablet {
    display: flex;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
  }
}

@media all and (min-width: 600px) and (max-width: 1199px) {
  .footer_component {
    padding: 40px 0 32px 0;
    background-color: #F6F8FC;
    overflow: hidden;
  }

  .content {
    width: 820px;
  }

  .logo__theme_logo {
    margin-top: 0;
  }

  .grid__theme_content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 0;
    width: 100%;
    margin-top: 36px;
  }

  .text__theme_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .list .list_item .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
  }

  .block__theme_social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 27px;

    h3 {
      margin: 0;
      font-family: $font_Euclid_Circular_B;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
    }

    .social_container {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      align-items: center;
      grid-gap: 10px;
      margin-top: 13px;
    }

    .social_container .social {
      width: 38px;
      height: 38px;
      background-color: #8C71FE;
    }
  }

  .block__theme_documents {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .block__theme_documents .list {
    grid-gap: 8px;
    max-width: 100%;
    align-items: center;
  }

  .block__theme_documents .list .list_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .block__theme_documents .list {
    grid-gap: 10px;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .content {
    width: 820px;
  }
}

@media all and (min-width: 600px) and (max-width: 899px) {
  .content {
    width: calc(100% - 80px);
  }
}

// mobile
@media all and (max-width: 599px) {
  .footer_component {
    padding: 40px 24px 32px 24px;
    background-color: #F6F8FC;
    overflow: hidden;
  }

  .content {
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 0;
  }

  .logo__theme_logo {
    margin: 0 auto;
  }

  .grid__theme_content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
    width: 100%;
    margin-top: 27px;
  }

  .navigation_item {
    width: fit-content;
    min-width: 127px;
  }

  .navigation_item_2, .navigation_item_4, .navigation_item_6 {
    margin: 0 0 0 auto;
  }

  .text__theme_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .list .list_item .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
  }

  .block__theme_social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    .social_container {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      align-items: center;
      grid-gap: 10px;
      margin-top: 10px;
    }

    .social_container .social {
      width: 38px;
      height: 38px;
      background-color: #8C71FE;
    }
  }

  .block__theme_documents {
    margin-top: 34px;
  }

  .block__theme_documents .list {
    grid-gap: 8px;
    max-width: 100%;
    align-items: center;
  }

  .block__theme_documents .list .list_item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .block__theme_documents .list {
    grid-gap: 10px;
  }
}

@media all and (max-width: 359px) {
  .footer_component {
    padding: 40px 16px 32px 16px;
    background-color: #F6F8FC;
    overflow: hidden;
  }
}

</style>