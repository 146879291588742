import {inject, onMounted, reactive} from "vue";
import {useStore} from "vuex";

let ManagementLoadedFonts = () => {
  const store = useStore();
  const emitter = inject('emitter'); // Inject `emitter`

  let loadedFonts = reactive({ // проверка загрузки шрифтов
    isLoaded: false,
    widthElem: undefined,
  });

  let watchForFontsLoading = () => { // отслеживает загрузку шрифтов и изменяет данные хранилища по их загрузке
    let elemFontLoaded = document.getElementById('fonts_loaded');
    let fontName = "Geometria"
    let fontSize = "1em";

    let updateFontsLoadedData = () => { // обновляет информацию по загрузке шрифтов
      loadedFonts.isLoaded = true;
      loadedFonts.widthElem = elemFontLoaded.offsetWidth;
      store.commit('setFontsIsLoaded', true);
      emitter.emit('fontsHasLoaded', 'loaded');
    };

    if (document.fonts.check(fontSize + ' ' + fontName)) { //  шрифт загружен
      updateFontsLoadedData();
    } else {
      let intervalFontsLoaded = setInterval(() => {
        if (document.fonts.check(fontSize + ' ' + fontName)) {
          updateFontsLoadedData();
          clearInterval(intervalFontsLoaded);
        }

      }, 200)
    }
  };

  onMounted(() => {
    watchForFontsLoading();
  });

  return {
    loadedFonts
  }
};

export default ManagementLoadedFonts