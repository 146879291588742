import {useRoute} from "vue-router";
import {ref, watch} from "vue";

let ManagementRouter = () => {
  const route = useRoute();
  const pagesWithoutMenu = [
    'personalData',
    'contract',
    'license',
    'legalInformation',
    'page_404',
  ];

  let currentRoute = ref(route.name);

  watch(route, (before) => {

    currentRoute.value = before.name;
  })

  return {
    pagesWithoutMenu,
    currentRoute,
  }
}

export default ManagementRouter