import {useRouter} from "vue-router";
import {inject} from "vue";

let Methods = (PhoneNumber, visibleMenu) => {
  const router = useRouter();
  const VueScrollTo = inject('VueScrollTo');

  let authorization = () => { // авторизация на платформе ГК
    location.href = 'https://ri-ege.ru/lk/gk/';
  };

  let registration = () => { // регистрация на платформе ГК
    location.href = 'https://ri-ege.ru/lk/gk/registration';
  };

  let call = () => { // позвонить по номеру
    document.location.href = ('tel:' + PhoneNumber.value);
  };

  // открывает окно с обратной связью
  let openFormFeedback = () => {
    if (visibleMenu.value) visibleMenu.value = false;

    let headerBlockHeight = document.getElementById('header').offsetHeight;
    VueScrollTo.scrollTo('#component_form_first', 1000, {offset: -(headerBlockHeight*2)});
  };

  let goToPage = (item, allowTransition = true) => { // перейти на страницу
    if (allowTransition) { // разрешить переход на след, страницу
      if (item.link && (item.link.length > 0)) {
        location.href = item.link + window.location.search;
      } else if (item.routerParams && (item.routerParams.name.length > 0)) {
        router.push(item.routerParams);
      }
    }
  };

  let scrollTo = (id) => { // проскролить контент к элементу
    if (visibleMenu.value) visibleMenu.value = false;

    let headerBlockHeight = document.getElementById('header').offsetHeight;
    VueScrollTo.scrollTo('#' + id, 1000, {offset: -(headerBlockHeight*2)});
  };

  return {
    scrollTo,
    authorization,
    call,
    goToPage,
    openFormFeedback,
    registration,
  }
};

export default Methods