import { createRouter, createWebHistory } from 'vue-router';

//import MainComponent from "@/components/MainPage/MainComponent";
import DocumentComponent from "@/components/DefaultComponents/DocumentsComponent/DocumentComponent";
import LegalInformation from "@/components/DefaultComponents/DocumentsComponent/LegalInformation/LegalInformation";
import Page404 from "./components/DefaultComponents/Page404/Page404.vue";

const routes = [
  /*{
    name: 'main',
    path: '/',
    component: MainComponent,
    props: true,
  },*/
  {
    name: 'personalData',
    path: '/personalData',
    component: DocumentComponent,
    props: true
  },
  {
    name: 'contract',
    path: '/contract',
    component: DocumentComponent,
    props: true
  },
  {
    name: 'license',
    path: '/license',
    component: DocumentComponent,
    props: true
  },
  {
    name: 'legalInformation',
    path: '/legalInformation',
    component: LegalInformation,
    props: true
  },
  {
    name: 'page_404',
    path: '/404',
    component: Page404,
    children: [],
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
];

const router = createRouter({
  history: createWebHistory((process.env.NODE_ENV === 'production')?('/docs/'):('')), // /lk/test_lending/
  //base: (process.env.NODE_ENV === 'production' ? '/docs/' : ''),
  routes
});

export default router;