import {menuStylisticSvg} from '../../../../svgStore/svgStoreFiles';
import { checkClickOutside } from '@/components/DefautFunctions/defaultFunctions';
import {onBeforeUnmount, ref, reactive} from "vue";

let ManagementMenu = () => {
  let visibleMenu = ref(false); // открыто ли меню
  let indexActiveMenuItem = ref(undefined); // index активного элемента меню / undefined - активного элемента нет
  let menu = reactive([ // меню сайта
    {
      id: 0,
      svg: menuStylisticSvg.home,
      showChildren: false,
      title: 'Главная',
      children: [],
      nameActivePages: ['main'],
      link: undefined,
      routerParams: {name: 'main', params: {}}
    },
    {
      id: 1,
      svg: menuStylisticSvg.subjects,
      showChildren: false,
      title: 'Предметы',
      link: undefined,
      children: [
        {
          id: 4,
          title: 'Русский язык',
          link: 'https://ri-ege.ru/subjects/russian/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 2,
          title: 'Обществознание',
          link: 'https://ri-ege.ru/subjects/society/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 6,
          title: 'Профильная математика',
          link: 'https://ri-ege.ru/subjects/mathematics/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 1,
          title: 'История',
          link: 'https://ri-ege.ru/subjects/history/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 5,
          title: 'Английский язык',
          link: 'https://ri-ege.ru/subjects/english/',
          routerParams: {name: '', params: {}}
        },
        {
          id: 3,
          title: 'Литература',
          link: 'https://ri-ege.ru/subjects/literature/',
          routerParams: {name: '', params: {}}
        },
      ],
      nameActivePages: [''],
      routerParams: {name: '', params: {}}
    },
    {
      id: 2,
      svg: menuStylisticSvg.aboutCompany,
      showChildren: false,
      title: 'О компании',
      link: 'https://ri-ege.ru/about/',
      children: [],
      nameActivePages: [''],
      routerParams: {name: '', params: {}}
    },
    {
      id: 3,
      svg: menuStylisticSvg.articles,
      showChildren: false,
      title: 'Статьи',
      link: 'https://zen.yandex.ru/id/5f187f2e4501c50d5aa4e806?lang=ru',
      children: [],
      nameActivePages: [''],
      routerParams: {name: '', params: {}}
    },
    {
      id: 4,
      svg: menuStylisticSvg.jobOpenings,
      showChildren: false,
      title: 'Сотрудничество',
      link: 'https://vk.com/riege_hr',
      children: [],
      nameActivePages: [''],
      routerParams: {name: '', params: {}}
    },
  ]);
  let contactTel = ref('8 (800) 101-07-79'); // контактный телефон менеджеров РИ

  // открыть/закрыть меню
  let changeVisibilityMenu = () => {
    visibleMenu.value = !visibleMenu.value;
  };

  let visibleMenuChildItems = (index) => { // управление отображением выпадающего блока с дочерними стр.
    if (indexActiveMenuItem.value !== index) {
      if (indexActiveMenuItem.value !== undefined) menu[indexActiveMenuItem.value].showChildren = false;
      menu[index].showChildren = true;
      indexActiveMenuItem.value = index;
    } else {
      if (indexActiveMenuItem.value !== undefined) menu[indexActiveMenuItem.value].showChildren = false;
      indexActiveMenuItem.value = undefined;
    }
  };

  let checkClickOutsideMenuChild = (event) => { // отслеживать клик вне модального окна списка пунктов меню
    if ((window.innerWidth >= 1200) && (indexActiveMenuItem.value !== undefined) && checkClickOutside(event, 'DIV', 'header_component__menu_item')) {
      menu[indexActiveMenuItem.value].showChildren = false;
      indexActiveMenuItem.value = undefined;
    }
  };

  // created
  document.addEventListener('click', checkClickOutsideMenuChild);

  // on before unmount
  onBeforeUnmount(() => {
    document.removeEventListener('click', checkClickOutsideMenuChild);
  })

  return {
    indexActiveMenuItem,
    menu,
    contactTel,
    visibleMenu,
    changeVisibilityMenu,
    visibleMenuChildItems,
    checkClickOutsideMenuChild
  }
};

export default ManagementMenu