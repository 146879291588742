import { createApp } from 'vue';
import App from './App.vue';
import { store } from './store/store';
import router from './Router';
import { clickOutside } from './globalDirectives/directives';
import { VueMaskDirective } from 'v-mask';
import VueScrollTo from 'vue-scrollto';
import { createMetaManager } from 'vue-meta'

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind
};

import mitt from 'mitt';
const emitter = mitt();
const app = createApp(App);

app.use(store);
app.use(router);
app.use(createMetaManager());
app.provide('emitter', emitter);
app.provide('VueScrollTo', VueScrollTo);
app.config.globalProperties.emitter = emitter;
app.directive('clickOutside', clickOutside);
app.directive('mask', vMaskV3);
app.directive('scroll-to', VueScrollTo);
app.mount('#app')
